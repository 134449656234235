import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import { Link as GatsbyLink } from 'gatsby';

const DateAndTagDiv = styled.div`
  font-size: 1.1rem;
  margin-top: 10px;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 1rem;
  }
`;

const DateTimeComponent = styled.time`
  display: block;
`;

const BlogEntryHeading = styled.h3`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 3rem;
  position: relative;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 2.2rem;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 2.2rem;
  }
`;

const BlogEntryDescription = styled.div`
  overflow-wrap: anywhere;
  line-height: 1.7778;
  font-weight: 400;
`;

const TagHeading = styled.h6`
  font-weight: 500;
  font-style: normal;
  display: inline-block;
  font-size: 18px;
  padding: 5px;
  margin: 10px 5px 0 0;
  background: black;
  color: white;
  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 16px;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 16px;
  }
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  color: black;
`;

const BlogEntry = ({ blogEntry }) => {
  const blogImage = getImage(blogEntry.blogHeadingImage.asset);
  return (
    <React.Fragment>
      <div>
        {/* Blog Heading Image */}
        <GatsbyLink to={'/' + blogEntry.blogSlug.current}>
          <GatsbyImage image={blogImage} alt={blogEntry.blogHeadingImage.alt} />
        </GatsbyLink>
        {/* Date and tags */}
        <DateAndTagDiv>
          <DateTimeComponent datetime={blogEntry.dateAndTme}>
            {blogEntry.dateAndTme}
          </DateTimeComponent>
          {blogEntry.blogTags.map((blogTag, i) => {
            return (
              <TagHeading key={i}>
                <span>{blogTag}</span>
              </TagHeading>
            );
          })}
        </DateAndTagDiv>
      </div>
      {/* Blog Description */}
      <div>
        <GatsbyLinkStyled to={'/' + blogEntry.blogSlug.current}>
          <BlogEntryHeading>{blogEntry.blogEntryTitle}</BlogEntryHeading>
        </GatsbyLinkStyled>
        <BlogEntryDescription>
          <PortableText blocks={blogEntry._rawBlogListDescription} />
        </BlogEntryDescription>
        <GatsbyLink to={'/' + blogEntry.blogSlug.current}>
          Go to Blog →
        </GatsbyLink>
      </div>
    </React.Fragment>
  );
};

export default BlogEntry;
