import React from 'react';
import styled from 'styled-components';
import BlogEntry from './BlogEntry';

const BlogSectionsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const BlogSectionDiv = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 3rem;
  border-block-end: 1px solid black;
  padding: 4rem 0px;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    grid-template-columns: 1fr;
    padding-top: 2rem;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    grid-template-columns: 1fr;
    padding-top: 2rem;
  }
`;

const EmptyBlogsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 125px;

  @media screen and (max-width: 750px) {
    margin-top: 50px;
  }
`;

const EmptyBlogsHeading = styled.h1`
  font-size: 24px;
  font-weight: 500;

  & span {
    margin-left: 5px;
  }
`;

const BlogEntries = ({ allBlogPosts }) => {
  return (
    <BlogSectionsDiv>
      {/* A single blog entry */}

      {allBlogPosts.length === 0 && (
        <EmptyBlogsDiv>
          <EmptyBlogsHeading>
            I haven't wrote any blogs yet
            <span role='img' aria-label='sad face emoji'>
              😢
            </span>
          </EmptyBlogsHeading>
        </EmptyBlogsDiv>
      )}

      {allBlogPosts.map((blogPost, i) => {
        return (
          <BlogSectionDiv key={blogPost._id}>
            <BlogEntry blogEntry={blogPost} />
          </BlogSectionDiv>
        );
      })}
    </BlogSectionsDiv>
  );
};

export default BlogEntries;
