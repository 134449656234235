import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

const BlogPaginationBarDiv = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  text-align: center;
  margin: 2rem 0px;
  place-items: center;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 10px;
    margin-bottom: 0;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;

const PaginationText = styled.div``;

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
`;

const disabledLink = {
  pointerEvents: 'none',
  color: '#b5b3b3',
};

const BlogPaginationBar = ({ currentPage, numPages }) => {
  let isFirst;
  if (currentPage === 0) {
    isFirst = true;
  } else {
    isFirst = currentPage === 1;
  }
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/blog' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <BlogPaginationBarDiv>
      <GatsbyLinkStyled
        to={prevPage}
        rel='prev'
        disabled={isFirst}
        style={isFirst ? disabledLink : {}}
      >
        ← Previous Page
      </GatsbyLinkStyled>
      <PaginationText>
        Page {currentPage} of {numPages}
      </PaginationText>
      <GatsbyLinkStyled
        to={nextPage}
        rel='next'
        disabled={isLast}
        style={isLast ? disabledLink : {}}
      >
        Next Page →
      </GatsbyLinkStyled>
    </BlogPaginationBarDiv>
  );
};

export default BlogPaginationBar;
