import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import BlogEntries from '../components/BlogPage/BlogEntries';
import BlogPaginationBar from '../components/BlogPage/BlogPagination';
import Layout from '../components/Shared/layoutWhite';

const BlogMainDiv = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding: 2rem;
`;

const BlogList = ({ data, pageContext }) => {
  const allBlogPosts = data.allSanityBlogPost.nodes;
  const { currentPage, numPages } = pageContext;
  return (
    <Layout>
      <BlogMainDiv>
        {/* Pagination Component */}
        <BlogPaginationBar currentPage={currentPage} numPages={numPages} />
        {/* All Blogs available per page */}
        <BlogEntries allBlogPosts={allBlogPosts} />
      </BlogMainDiv>
    </Layout>
  );
};

export const allBlogPostsQuery = graphql`
  query allBlogPostsQuery($limit: Int!, $skip: Int!) {
    allSanityBlogPost(
      limit: $limit
      skip: $skip
      sort: { fields: _createdAt, order: DESC }
    ) {
      nodes {
        _id
        blogEntryTitle
        blogHeadingImage {
          caption
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        dateAndTme(formatString: "DD MMMM, YYYY")
        _rawBlogBody
        blogTags
        blogSlug {
          current
        }
        _rawBlogListDescription
      }
    }
  }
`;

export default BlogList;
